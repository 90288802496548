import { ChakraProvider } from "@chakra-ui/react";
import { customTheme } from "./theme";
import { useLocation } from "react-router-dom";
// import Home from "./Page/Home";
// import Gallery from "./Page/gallery";
// import Layout from "./components/Layout";
// import About from "./Page/About";
import ReactGA from "react-ga";
import React from "react";
import ComingSoon from "./Page/coming-soon";
const GA_ID = "UA-222576032-1";
ReactGA.initialize(GA_ID);

const usePageView = () => {
  const location = useLocation();
  React.useEffect(() => {
    // @ts-ignore
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize(GA_ID);
      // @ts-ignore
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
};

function App() {
  usePageView();
  return (
    <ChakraProvider theme={customTheme}>
      <ComingSoon />
      {/* <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="products" element={<About />} />
          <Route path="migrate" element={<ComingSoon />} />
        </Routes>
      </Layout> */}
    </ChakraProvider>
  );
}

export default App;
