import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
});

const overrides = {
  breakpoints,
  color: {
    primary: "white",
  },
  fonts: {
    body: `'Inter', sans-serif`,
    timer: `'Orbitron', sans-serif`,
  },
  styles: {
    global: (props: any) => ({
      html: {},
      "html,body, * ": {
        fontFamily: "body",
        boxSizing: "border-box",
        p: "0px",
        m: "0px",
      },
    }),
  },
};

export const customTheme = extendTheme(overrides);
