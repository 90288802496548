import React from 'react'
import { Flex, Heading, Image, Link } from "@chakra-ui/react"
import Logo from '../assets/vase_logo.png'
import styled from '@emotion/styled'


const StyledHeader = styled(Heading)`
  background: -webkit-linear-gradient(158deg, #74EBD5 0%, #4e5ca9 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bold;
`


const ComingSoon = () => {
  return (
    <Flex minH='100vh'
      bgColor={'#fefefe'}
    // bgGradient='linear-gradient(330deg, #1b284a 0%, #0a0a0a 72%)'
    >
      <Flex justify={'center'} flexDir='column' textAlign={'center'} maxW={'866px'} m='auto' p={'1rem'}>
        <Image maxW={'100px'} w={'100%'} h='100%' src={Logo} alt='logo' mx={'auto'}></Image>
        <StyledHeader fontSize={{ base: '2.5rem', lg: '5.5rem' }}
        >Coming soon</StyledHeader>
        <Heading color={'#323131'} fontSize={{ base: '1.1rem', lg: '1.5rem' }} py={'1rem'}>
          Please visit
          <Link
            as={'a'}
            sx={{
              px: '8px',
              transition: '.23s linear',
              color: 'purple',
              _hover: {
                color: 'skyblue'
              }
            }}
            href='https://www.vaselabs.io'>www.vaselabs.io</Link>to stay updated
        </Heading>
      </Flex>
    </Flex>
  )
}

export default ComingSoon;

